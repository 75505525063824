// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:        #165169;
$dark-blue-bg:         #165169;
$middle-blue-bg:       #b8cad4;
$light-blue-bg:        #d8dfe9;

// Fonts
$narkis-bold-font: 'NarkisBlockMFbold';
$narkis-medium-font: 'NarkisBlockMFmedium';
$narkis-regular-font: 'NarkisBlockMFregular';

$oenapro-light-font: 'OENaPro-Light';
$oenapro-semibold-font: 'OENaPro-SemiBold';

