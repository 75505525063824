@font-face { font-family: 'NarkisBlockMFbold'; src: url('../fonts/narkisblock-bold_mfw.eot'); }
@font-face { font-family: 'NarkisBlockMFbold'; src: url('../fonts/narkisblock-bold_mfw.woff'); }
@font-face { font-family: 'NarkisBlockMFbold'; src: url('../fonts/narkisblock-bold_mfw.ttf'); }
@font-face { font-family: 'NarkisBlockMFbold'; src: url('../fonts/narkisblock-bold_mfw.svg'); }

@font-face { font-family: 'NarkisBlockMFmedium'; src: url('../fonts/NarkisBlock-Medium_MFW.eot'); }
@font-face { font-family: 'NarkisBlockMFmedium'; src: url('../fonts/NarkisBlock-Medium_MFW.woff'); }
@font-face { font-family: 'NarkisBlockMFmedium'; src: url('../fonts/NarkisBlock-Medium_MFW.ttf'); }
@font-face { font-family: 'NarkisBlockMFmedium'; src: url('../fonts/NarkisBlock-Medium_MFW.svg'); }

@font-face { font-family: 'NarkisBlockMFregular'; src: url('../fonts/narkisblock-regular_mfw.eot'); }
@font-face { font-family: 'NarkisBlockMFregular'; src: url('../fonts/narkisblock-regular_mfw.woff'); }
@font-face { font-family: 'NarkisBlockMFregular'; src: url('../fonts/narkisblock-regular_mfw.ttf'); }
@font-face { font-family: 'NarkisBlockMFregular'; src: url('../fonts/narkisblock-regular_mfw.svg'); }

@font-face { font-family: 'OENaPro-Light'; src: url('../fonts/OENaPro-Light.eot'); }
@font-face { font-family: 'OENaPro-Light'; src: url('../fonts/OENaPro-Light.woff'); }
@font-face { font-family: 'OENaPro-Light'; src: url('../fonts/OENaPro-Light.woff2'); }

@font-face { font-family: 'OENaPro-SemiBold'; src: url('../fonts/OENaPro-SemiBold.eot'); }
@font-face { font-family: 'OENaPro-SemiBold'; src: url('../fonts/OENaPro-SemiBold.woff'); }
@font-face { font-family: 'OENaPro-SemiBold'; src: url('../fonts/OENaPro-SemiBold.woff2'); }