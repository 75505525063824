@media screen and (min-width: $screen-md-min) { 
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, 
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12
    {
        padding-left: 20px;
        padding-right: 20px;
    }
   .no-padding-l{padding-left: 0px;}
    .no-padding-r{padding-right: 0px;}
    .no-padding{padding: 0px;}
    .dark-bg, .light-bg, .middle-bg {
    margin-bottom: 40px;
    }
    .home-content-left {
    padding-right: 40px;
    }
}

@media (min-width:1480px) {
    .container{
        width: 1400px!important;
    }
    .team-img {
    min-height: 238px;
    }
}
@media (min-width:1200px) {
    .container{
        width: 1120px;
    }
}
@media (max-width:1440px) {
/*    .contact-form .frm_form_fields input[type=email]{
        width: 86%;
    }*/
}
@media (max-width:1366px) {
   .team-text h2 {
    font-size: 25px;
}
.team-text a.read-more{
    width: 30px;
    height: 30px;
    background-size: cover;
}
}

@media (max-width:1280px) {
/*    .contact-form .frm_form_fields input[type=text] {
    width: 89%;
} 
.contact-form .frm_form_fields input[type=email] {
    width: 84%;
}*/
}

@media (max-width:1152px) {
/*    .contact-form .frm_form_fields input[type=text] {
    width: 88%;
} 
.contact-form .frm_form_fields input[type=email] {
    width: 82%;
}*/
}
@media (max-width:1024px) {
/*    .contact-form .frm_form_fields input[type=text] {
    width: 85%;
} 
.contact-form .frm_form_fields input[type=email] {
    width: 79%;
}*/
.contact-form .frm_form_fields textarea {
    max-height: 180px;
    overflow: auto;
    overflow-x: hidden;
}
}

@media (max-width:768px) {
    
 .header-info-container {
    padding-bottom: 15px;
    margin-bottom: 15px;
}   
 .menu-container {
    position: relative;
}   

 /*Mobile menu CSS style start*/
 .nav {
    padding: 0px 15px;
}
.nav>li, .nav>li>a,
.nav>li>a:focus, 
.nav>li>a:hover, 
.nav li.current-menu-item a, 
.nav li.current-menu-parent a {
    text-align: right;
    width: 100%;
}
.nav>li>a {
    padding: 15px 0px;
    border-bottom: 2px solid #165169;
}
.nav>li:last-child>a{
    border-bottom: none;
}
.bt-menu-trigger, 
.nav-primary button.close {
    position: relative;
    top: 1px;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 100;
    padding: 5px;
    background: #fff;
    border: 2px solid #165169;
    border-radius: 50px;
    margin: 0px;
}
.bt-menu-trigger span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 30px;
    height: 2px;
    margin-top: 0px;
    background-color: #165169;
    font-size: 0px;
    user-select: none;
    transition: background-color 0.3s;
    text-align: center;
    margin-left: 8px;
}
.close span {
    background-color: transparent;
}
.bt-menu-trigger span.first-span,
.bt-menu-trigger span.last-span {
    position: absolute;
    left: 0;
    background: #165169;
    content: '';
    transition: transform 0.3s;
    z-index: 101;
}
.bt-menu-trigger span.first-span {top:40%;}
.bt-menu-trigger span.last-span { top:60%;}
.bt-menu-trigger span.first-span {
    transform: translateY(-250%);
    -webkit-transform: translateY(-250%);
}

.bt-menu-trigger span.last-span {
    transform: translateY(250%);
    -webkit-transform: translateY(250%);
}
.close span.first-span {
    transform: translateY(0) rotate(45deg);
    -webkit-transform: translateY(0) rotate(45deg);
    top:50%;
}

.close span.last-span {
    transform: translateY(0) rotate(-45deg);
    -webkit-transform: translateY(0) rotate(-45deg);
    top:50%;
}
.close {
    opacity: 1!important;
}
/*Mobile menu CSS style end*/   
a.logo h1 {
    font-size: 36px;
}
a.logo h4 {
    font-size: 20px;
}
}


@media (max-width:767px) {
    .main { 
    margin-top: 25px;
    }
 .banner{
    position: fixed;
    z-index: 1;
    width: 100%;
    background: #fff;
    min-height: 78px;
    top: 0px;
}   
.wrap{
    margin-top: 78px;
}
 #menu-menu-1{
    position: fixed;
    top: 78px;
    z-index: 100;
    background: #fff;
    left: 0;
}   
 body{
    font-size: 23px;
}   
 .no-padding-l{
     padding-left: 15px;
 }
 .no-padding-r,
 .home-content-left{
     padding-right: 15px;
 }
 .home-content-left a,
 .home-content-top-img img,
 .home-content-middle{
     width: 100%;
 }
 .product-content-bottom-right-text{
     float: right;
     width: 100%;
 }
 .home-content-bottom-right,
 .home-content-bottom-left-img-b{
     padding: 0px;
 }
 .home-content-left h3 {
    text-align: right;
    min-height: 95px;
    display: block;
}
 .home-content-middle-info {
    padding-bottom: 60px;
}
 .home-content-middle, 
 .home-content-top {
    display: block;
} 
.bottom-right-info {
    margin-left: 15px;
    margin-right: 15px;
    text-align: right;
    width: 92.5%;
    padding: 40px 0px 0px 0px;
}
.bottom-right-info > .col-sm-12{
    padding: 0px;
}
.home-content-middle-info {
    background: #165169;
}
.home-content-middle {
    background: #fff;
    width: 100%;
    padding: 0px 15px 15px;
}
.home-content-bottom-left {
    float: none;
}
.page-img {
    margin-bottom: 20px;
}
.footer-top span.s1{
    border-left: none;
}
.footer-bottom span.fl-left {
    float: right;
}
.team-text {
    min-height: auto;
}
.team-single-info {
    margin-bottom: 0px;
}
.content-info {
    margin-top: 40px;
}
p {
    margin: 0px;
}
.page-template-template-product-lobby .content-info,
.page-template-template-team-lobby .content-info {
    margin-top: 20px;
}
.contact-info ul li:last-child {
    margin-bottom: 0px;
}
.page-template-template-product-lobby .dark-bg, 
.page-template-template-product-lobby .light-bg, 
.page-template-template-product-lobby .middle-bg,
.product-info-right h1,
.product-info-right h2{
    margin-bottom: 20px;
}
.product-info-left {
    padding-top: 20px;
}
}

@media (max-width:600px) {
}

@media (max-width:414px) {
/* .contact-form .frm_form_fields input[type=text] {
    width: 83%;
}  
  .contact-form .frm_form_fields input[type=email] {
    width: 76%;
}  */
}


@media (max-width:380px) {
/*    .contact-form .frm_form_fields input[type=text] {
    width: 81%;
}
  .contact-form .frm_form_fields input[type=email] {
    width: 73%;
} */
.contact-info ul li {
    font-size:20px;
}
.contact-info .glyphicon {
    width: 24px;
    height: 24px;
}
.contact-info .glyphicon:before {
    width: 24px;
    height: 24px;
    padding: 5px;
}
}

@media (max-width:320px) {
/*      .contact-form .frm_form_fields input[type=text] {
    width: 77%;
}
  .contact-form .frm_form_fields input[type=email] {
    width: 67%;
}*/
.footer-top span{
    border-left: none;
}
.team-single-info h1 {
    font-size: 44px;
}
}