body{
    font-family: $narkis-regular-font, Helvetica;
    background: #fff;
    font-size: 25px;
    line-height: 1.3;
    color: $brand-primary;
}
a.read-more,
span.read-more{
    width: 40px;
    height: 40px;
    float: left;
    bottom: 15px;
    position: absolute;
    left: 15px;
    background-size: 100%;
}
a.dark-arrow,
span.dark-arrow{
    background: url(../../assets/images/dark-arrow.png) no-repeat center center transparent;
}
a.wh-arrow,
span.wh-arrow{
    background: url(../../assets/images/wh-arrow.png) no-repeat center center transparent;
}
/*.glyphicon-menu-left{
    top:-4px;
    right: -3px;
}
.glyphicon-menu-left:before{
    content:"\e257";    
    color: #fff;
    font-size: 22px;
}*/
img {
    width: 100%;
}
a, a:hover{
    color:$brand-primary;
    text-decoration: none;
}
a:focus {
    outline:none;
    text-decoration: none;
}
ul li {
    list-style: inherit;
}
.no-padding-l{padding-left: 0px;}
.no-padding-r{padding-right: 0px;}
.no-padding{padding: 0px;}
.main {
    width: 100%!important;
}
.header-info-container {
    border-bottom: 2px solid #165169;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;
    margin-bottom: 40px;
}
.logo-container a:hover{text-decoration: none;}

a.logo h1,
a.logo h4{
    color: $brand-primary;
    margin: 0px;
}
a.logo h1{
    font-size: 64px;
    font-family: $oenapro-semibold-font;
}
a.logo h4{
    font-size: 37px;
    font-family: $narkis-regular-font;
}
.logo-container{
    text-align: right;
    padding-right: 0px!important;
}
.menu-container{padding-left: 0px;}
.nav {
    padding:0px;
    float: left;
}
.nav>li{
   float: right;
   display:inline-block;
   text-align:center;
}
.nav>li>a{
    color: $brand-primary;
    font-size: 25px;
    padding: 0px 15px;
    line-height: 25px;
    display:inline-block;
    text-align:center;
    font-family: $narkis-regular-font;
}
.nav>li>a:hover, 
.nav>li>a:focus,
.nav li.current-menu-parent a,
.nav li.current-menu-item a {
    color: $brand-primary;
    background: transparent;
    text-align:center;
}
.nav>li>a:hover {
    color: #b9cad2;
}
.nav li.active a,
.nav li.current-menu-parent a,
.nav li.current-menu-item a {
    font-family: $narkis-bold-font;
}
.nav>li:last-child>a {
    padding-left: 0px
}
