//
// Tooltips
// --------------------------------------------------


// Base class
// $opposite : left;
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  visibility: visible;
  font-size: $font-size-small;
  line-height: 1.4;
  @include opacity(0);

  &.in     { @include opacity($tooltip-opacity); }
  &.top    { margin-top:  -3px; padding: $tooltip-arrow-width 0; }
  &.#{$opposite}  { margin-#{$default}:  3px; padding: 0 $tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: $tooltip-arrow-width 0; }
  &.#{$default}   { margin-#{$default}: -3px; padding: 0 $tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: 3px 8px;
  color: $tooltip-color;
  text-align: center;
  text-decoration: none;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip {
  &.top .tooltip-arrow,
  &.bottom .tooltip-arrow,
  &.#{$opposite} .tooltip-arrow,
  &.top-#{$default} .tooltip-arrow,
  &.bottom-#{$default} .tooltip-arrow {
    @if $default == right {
      left: auto;
    }
  }
  &.top .tooltip-arrow {
    bottom: 0;
    #{$default}: 50%;
    margin-#{$default}: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-#{$default} .tooltip-arrow {
    bottom: 0;
    #{$default}: $tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-#{$opposite} .tooltip-arrow {
    bottom: 0;
    #{$opposite}: $tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.#{$opposite} .tooltip-arrow {
    top: 50%;
    #{$default}: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    border-#{$opposite}-color: $tooltip-arrow-color;
  }
  &.#{$default} .tooltip-arrow {
    top: 50%;
    #{$opposite}: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-#{$default}-color: $tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    #{$default}: 50%;
    margin-#{$default}: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-#{$default} .tooltip-arrow {
    top: 0;
    #{$default}: $tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-#{$opposite} .tooltip-arrow {
    top: 0;
    #{$opposite}: $tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
}
