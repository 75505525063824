.container-fluid {
    max-width:1400px; 
    padding-left:20px; 
    padding-right:20px;
}
.page-template-template-team-lobby header{
margin-bottom:10px;
}

.home-content-top{
    display: flex;
    margin-bottom: 40px;
}
.home-content-top-img{
    margin-left: -20px;
}
.home-content-top-img img{
    width: calc(100% - 20px);
}
.home-content-middle{
    display: flex;
    background: $dark-blue-bg;
    width: 27%;
}
.home-content-middle-info{
    font-size: 25px;
    color: #fff;
    align-items: flex-end;
    line-height: 1.2;
    position: relative;
    padding: 20px;
    height: 100%;
}
.home-content-middle-info p{
    margin: 0px;
}
.home-content-left{
    display: flex;
    align-items: flex-end;
    padding-right: 40px;
}
.home-content-left h3{
    margin: 0px;
    padding: 15px;
    background:$light-blue-bg;
    color:$brand-primary;
    font-size: 50px;
    text-align: center;    
    min-height: 250px;
    display: flex;
    align-items: center;
    font-family: $oenapro-light-font;
}
.product-info-right h1,
.product-content-bottom-right-text h3,
.product-content-top-left h3,
.product-info h1,
.page-info h2,
.team-single-info h1,
.team-single-info h2,
.home-content-bottom h3{
    font-size: 50px;
    margin: 0px;
    color:$brand-primary;
    font-family: $oenapro-light-font;
}
.home-content-bottom .home-content-bottom-right h3{
    line-height: 40px;
}
.team-single-container .team-img {
    margin-top: 15px;
}
.team-single-info h1,
.page-info h2{
    line-height: 32px;
    margin-bottom: 20px;
}
.team-single-info h1{
    line-height: 1.1;
    margin-bottom: 20px;
}
.page-img{
    margin-top: 10px;
}
.product-info-right h4{
    font-size: 25px;
    line-height: 1.3;
}
.product-info-right h2{
      font-family: $narkis-medium-font; 
      margin: 0px;
      font-size: 25px;
}
.contact-form .frm_form_fields h3,
.frm_message h2{
    font-size: 50px!important;
    margin: 0px!important;
    color:$brand-primary!important;
    text-align: right;
    font-family: $oenapro-light-font;
    line-height: 32px;
    margin-bottom: 20px!important;
}
.team-single-info h2{
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.team-single-info{
    margin-bottom: 170px;
}
.dark-bg,
.middle-bg, 
.light-bg{
    padding: 20px;
    padding-bottom: 40px;
    margin-bottom: 30px;   
}
.dark-bg{
    background:$dark-blue-bg;
}
.middle-bg{
    background:$middle-blue-bg;
}
.light-bg,.gallery-caption{
    background:$light-blue-bg;
}
.home-content-bottom-right{
    position: relative;
}
.home-content-bottom-right-r{
   padding-bottom: 40px;
}
.home-content-bottom-right-r .light-bg{
    min-height: 252px;
    margin-bottom: 0;
    height: 100%;
    position: relative;
    padding: 40px 20px;
}
/*.home-content-bottom-right-r .light-bg a{
    left: 30px;
}*/
/*.product-content-top-left a .glyphicon-menu-left:before,
.product-content-bottom-right-text a .glyphicon-menu-left:before,
.team-text a .glyphicon-menu-left:before,
.home-content-bottom-left-img-b .middle-bg a .glyphicon-menu-left:before,
.home-content-bottom-right-r .light-bg a .glyphicon-menu-left:before,
.home-content-bottom-right-l .middle-bg a .glyphicon-menu-left:before {
    color:$brand-primary;
}*/
/*.product-content-top-left .middle-bg a.read-more,
.product-content-bottom-right-text .light-bg a.read-more,
.home-content-bottom-left-img-b .middle-bg a.read-more,
.home-content-bottom-right-l .middle-bg a.read-more,
.home-content-bottom-right-r .light-bg a.read-more,
.team-text a.read-more {
    border: 1px solid #165169;
}*/
.home-content-bottom-right-l-info.middle-bg h3{
        max-width: 84%;
}
.home-content-bottom-right-l-info h3{
    display: flex;
    align-items: center;
    min-height: 110px;
}
.home-content-bottom-left{
    float: left;
}
.home-content-bottom-left-img h3,
.bottom-right-info-img h3{
    color: #fff;
}
.product-content-bottom-right-text .light-bg,
.product-content-bottom-left-img .dark-bg,
.product-content-top-right-img .dark-bg,
.product-content-top-left .middle-bg,
.home-content-bottom-left-img-b .middle-bg,
.home-content-bottom-left-img .dark-bg,
.bottom-right-info-img .dark-bg {
    min-height: 150px;
    position: relative;
}
.product-content-bottom-right-text .light-bg,
.product-content-top-left .middle-bg{
    min-height: 250px;
}
.bottom-right-info{
    text-align: center;
    padding: 40px;
    position: absolute;
    bottom: 0px;
}
.bottom-right-info .light-bg{
    margin-bottom: 0;
    padding: 0px;
    min-height: 110px;
    display: flex;
    align-items: center;
    padding: 40px 20px;
}
.bottom-right-info .light-bg a{
    width: 100%;
}
.team-img{
    min-height: 184px;
}
.team-text{
    position: relative;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.team-text a.dark-arrow {
        left: 20px;
        bottom: auto;
}
.team-text h2{
    margin: 0px;
    color: $brand-primary;
    font-size: 32px;
    font-family: $oenapro-light-font;
    line-height: 24px;
    margin-top: -9px;
}
.contact-info img{
    max-width: 300px;
}
.contact-info .glyphicon{
    color: $brand-primary;
    font-size: 16px;
    float: right;
    margin-left: 10px;
    width: 30px;
    height: 30px;
}
.contact-info .glyphicon:before {
    /*border: 1px solid #165169;*/
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    float: right;
    -webkit-box-shadow: 0px 0px 0px 1.3px #165169;
    -moz-box-shadow:0px 0px 0px 1.3px #165169;
    box-shadow: 0px 0px 0px 1.3px #165169;
}
.contact-info ul{
    padding: 0px;
    margin-top: 30px;
}
.contact-info ul li{
    list-style: none;
    margin-bottom: 25px;
}
.contact-form .frm_form_fields {
    color: $brand-primary;
}
.contact-form .frm_form_field {
    position: relative;
}
.contact-form .frm_form_fields #frm_field_15_container label,
.contact-form .frm_form_fields #frm_field_17_container label {
    font-weight: normal;
    width: 10%;
}
.contact-form .frm_form_fields .frm_form_fields #frm_field_16_container label {
    font-weight: normal;
     width: 13%;
}
.contact-form .frm_form_fields input[type="email"],
.contact-form .frm_form_fields input[type="text"] {
    background: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #165169;
    outline: none;
    padding: 0px 10px;
    margin-bottom: 15px;    
    max-width: 100%!important;
    width: 85%;
    z-index: 1;
    position: relative;
    text-align: right;
}
.contact-form .frm_form_fields input[type="text"] {
    width: 89%;
}
.contact-form .frm_form_fields input[type=submit] {
    background: url(../../assets/images/dark-arrow.png) no-repeat left center transparent;
    box-shadow: none;
    border: none;
    text-align: left;
    float: left;
    outline: none;    
    height: 40px;
    padding-left: 55px;
}
.contact-form .frm_ajax_loading {
    float: left;
    margin-top: 13px;
}
.contact-form .frm_form_fields textarea {
    background: transparent;
    box-shadow: none;
    border: 2px solid #165169;
    outline: none;
    padding: 0px 10px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 100%!important;
}
.contact-form .frm_form_fields .frm_error{
    color: #b9cad2;
    position: absolute;
    width: 80%;
    right: 80px;
    top: 0px;
    z-index: 0;
}
.contact-form #frm_field_18_container .frm_error{
    width: 70%;
    right: 160px;
    z-index: 0;
}
.contact-form .frm_error_style{
    display: none;
}
.contact-form .light-bg{
    padding: 40px 30px;
}
.product-content-bottom-left-img h3,
.product-content-top-right-img h3{
    font-size: 35px;
    margin: 0px;
    color:#fff;
    font-family: $oenapro-semibold-font
}
.product-content-bottom-left-img a,
.product-content-top-right-img a{
    color:#fff;
}
.product-content-top-left h3,
.product-content-bottom-right-text h3,
.product-info-left h2{
    font-size: 35px;
    font-family: $oenapro-semibold-font;
    margin: 0px;        
}
.product-info-left h2{
    margin-bottom: 10px;        
}
.product-info-left hr{
    border-top: 2px solid #165169;
    margin: 20px 0px;
}
.product-info-left h2:first-child{
    border-top: none;
    padding-top: 0px;
}
.product-info-left{
    padding-top: 49px;
}
.product-content-bottom-left-img,
.product-content-top-right-img{
    color:#fff;
}
.product-info{
    margin-bottom: 30px;
}
.product-content-bottom-left-img{
    float: left;
}
.img-title{
    font-weight: bold;
}
.gallery-caption h3{
    margin: 0px;
    font-family: $narkis-bold-font;
}
.product-gallery{
    float: right;
    width: 100%;
    margin-top: 30px;
}