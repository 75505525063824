.content-info{
    font-size: 18px;
    color:$brand-primary;
    margin-top: 30px;
}
.page-template-template-product-lobby .content-info,
.page-template-template-team-lobby .content-info{
    margin-top: 0px;
}
.footer-top{
    border-bottom: 2px solid #165169;
    border-top: 2px solid #165169;
    padding: 15px 0px;
    margin-bottom: 15px;    
    float: right;
    width: 100%;
}
.footer-top span{
     float: right;
    text-align: right;
    margin-left: 15px;
    border-left: 1px solid #165169;
    padding-left: 15px;
    line-height: 18px;
}
.footer-top span:last-child{
    margin-left: 0px;
    border-left: none;
    padding-left: 0px;
}
.footer-bottom span.fl-left{
    float: left;
}
.footer-bottom{
    margin-bottom: 15px;
}